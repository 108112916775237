/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//const LogRocket = require("logrocket");
//const Sentry = require("@sentry/browser");
exports.onClientEntry = () => {
  if (
    process.env.NODE_ENV === "production" &&
    typeof window !== "undefined" &&
    window.location.href.indexOf("travelnursing.org") !== -1
  ) {
    // Record sessions with errors:
    // We currently have the free plan which
    // will log 1000 sessions per month
    // and the month resets on the 27th

    /*LogRocket.init("full-beaker/travelnursing");
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });*/
  }

  // Polyfills
  if (typeof String.prototype.normalize === "undefined") {
    // eslint-disable-next-line
    String.prototype.normalize = function() {
      return this;
    };
  }
};

exports.onRouteUpdate = (_, pluginOptions) => {
  // wrap inside a timeout to ensure the title has properly been changed
  if (typeof window.dataLayer !== 'undefined') {
    setTimeout(() => {
      window.dataLayer.push({ event: `gatsby-route-change` })
    }, 50);
  }
};
